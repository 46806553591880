import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { colors } from "../../Assets/styles/colors";
import { ReactComponent as SentIcon } from "../../Assets/icons/empty-check.svg";
import { ReactComponent as ProcessingIcon } from "../../Assets/icons/sentMessageIcon.svg";
import { ReactComponent as TranslatedIcon } from "../../Assets/icons/chat-translated.svg";
import { ReactComponent as ForwardedIcon } from "../../Assets/icons/forwardIcon.svg";
import { localizationStrings } from "../../Localization/Localization";
import Linkify from "linkify-react";
import ReplymessageElement from "./ReplyMessageElement";
import { MediaPlayer, MediaProvider } from "@vidstack/react";
import {
  defaultLayoutIcons,
  DefaultVideoLayout,
} from "@vidstack/react/player/layouts/default";
import "@vidstack/react/player/styles/default/theme.css";
import "@vidstack/react/player/styles/default/layouts/video.css";
import FileMessageComponent from "./FileMessageComponent";
import { isMobile } from "react-device-detect";
import { useAuthService } from "../../Context/AuthContext";
import { findTranslatedTextForUser } from "../../Helpers/chatHelper/ChatHelper";

const Message = ({
  text,
  sentTime,
  status,
  image,
  reactions,
  messageId,
  conversationId,
  handleShowFullScreenImg,
  messageDeleted,
  displayRightClickMenu,
  isMessageRead,
  messageObject,
  forwarded,
  messageParent,
  file,
  video,
}) => {
  let imageHeight;
  let imageWidth = 350;
  const longPressTimeout = useRef(null);

  const { user } = useAuthService();

  const scaledHeightForMaxWidth = (scale) => {
    let aspectRatio = parseFloat(image.width) / parseFloat(image.height);
    let newHeight = scale / aspectRatio;
    return parseFloat(newHeight);
  };

  const scaledwidthForHeight = (scaledWidth) => {
    let aspectRatio = parseFloat(image.height) / parseFloat(image.width);
    let newWidth = scaledWidth / aspectRatio;
    return parseFloat(newWidth);
  };

  var details;

  switch (status) {
    case "sent":
      details = (
        <Details>
          <SendTime>
            <p>{sentTime}</p>
          </SendTime>
          <Status isread={isMessageRead}>
            <SentIcon />
          </Status>
        </Details>
      );
      break;

    case "processing":
      details = (
        <Details>
          <SendTime>
            <p>{sentTime}</p>
          </SendTime>
          <Status isread={isMessageRead}>
            <ProcessingIcon />
          </Status>
        </Details>
      );
      break;

    case "translated":
      details = (
        <Details>
          <SendTime>
            <p>{!messageDeleted && sentTime}</p>
          </SendTime>
          <Status isread={isMessageRead}>
            <TranslatedIcon />
          </Status>
        </Details>
      );
      break;
    default:
      break;
  }

  if (image) {
    if (window.innerWidth < 990) {
      imageHeight = scaledHeightForMaxWidth(300);
    } else {
      imageHeight = scaledHeightForMaxWidth(400);
    }
    imageWidth = scaledwidthForHeight(imageHeight);
  }

  //MOBILE touch context menu
  const handleTouchStart = (e) => {
    const touch = e.touches[0];
    e.preventDefault();
    longPressTimeout.current = setTimeout(() => {
      displayRightClickMenu(e, messageId, conversationId, messageObject);
    }, 500); // 800 ms for long press detection
  };

  const handleTouchEnd = () => {
    clearTimeout(longPressTimeout.current);
  };

  return (
    <Container>
      <MessageWrapper
        onContextMenu={(e) =>
          displayRightClickMenu(e, messageId, conversationId, messageObject)
        }
        onTouchStart={(e) => handleTouchStart(e)}
        onTouchEnd={handleTouchEnd}
        imgcontainer={image || video}
      >
        <ItemWrapper>
          {forwarded && (
            <ForwardedInfo image={image || video}>
              <ForwardedIcon />
              <p>Forwarded</p>
            </ForwardedInfo>
          )}

          {messageParent && !messageDeleted && (
            <ReplymessageElement
              text={
                findTranslatedTextForUser(
                  messageParent.message_translations,
                  user
                ).translation
              }
              image={messageParent.image}
              messageDeleted={messageParent.deleted}
              sender={messageParent.user?.name}
              file={messageParent.document}
              video={messageParent.video}
            />
          )}
          {file && <FileMessageComponent file={file} />}

          {image && (
            <ImageWrappr>
              <img
                height={imageHeight}
                width={imageWidth}
                src={image.url}
                alt="pht"
                onClick={() =>
                  handleShowFullScreenImg(image.url, messageId, conversationId)
                }
              />
            </ImageWrappr>
          )}
          {video && (
            <VideoPreview>
              <video src={video.video_url} width={"100%"} controls></video>
            </VideoPreview>

            // <VideoPreview>
            //     <MediaPlayer
            //         load="visible"
            //         posterLoad="visible"
            //         controls

            //         autoPlay={false}
            //         // playsInline
            //         src={video.video_url}
            //     >
            //         <MediaProvider />
            //         <DefaultVideoLayout
            //             thumbnails={video.thumbnail.url}
            //             icons={defaultLayoutIcons}
            //         />
            //     </MediaPlayer>
            // </VideoPreview>
          )}
          {text.length > 0 && (
            <TextWrapper image={image || video}>
              <Linkify options={{ target: "_blank" }}>
                <p>
                  {!messageDeleted ? (
                    text
                  ) : (
                    <i>{localizationStrings.chat.message_deleted}</i>
                  )}
                </p>
              </Linkify>
            </TextWrapper>
          )}

          {messageDeleted && (
            <h3>
              <i>{localizationStrings.chat.message_deleted}</i>
            </h3>
          )}
        </ItemWrapper>

        {reactions[0] && !messageDeleted && (
          <ReactionContainer image={(image || video) && text.length > 0}>
            {reactions[0]?.reaction}
          </ReactionContainer>
        )}
      </MessageWrapper>
      {!messageDeleted && details}
    </Container>
  );
};

export default Message;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  gap: 7px;
`;
const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
const MessageWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-left: auto;
  gap: 10px;
  width: fit-content;
  max-width: 400px;
  background: ${colors.purple};
  padding: ${(props) => (props.imgcontainer ? "4px" : "16px")};
  border-top-left-radius: ${(props) => (props.imgcontainer ? "4px" : "20px")};
  border-bottom-left-radius: ${(props) =>
    props.imgcontainer ? "4px" : "20px"};
  border-top-right-radius: ${(props) => (props.imgcontainer ? "4px" : "8px")};
  border-bottom-right-radius: ${(props) =>
    props.imgcontainer ? "4px" : "20px"};

  a {
    color: ${colors.white};
  }
  h3 {
    overflow: hidden;
    text-overflow: ellipsis;
    white-spaces: pre-wrap;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    color: ${colors.chatElementColor};
    padding: 0 5px;
  }
  @media (max-width: 990px) {
    max-width: 300px;
  }
`;
const Details = styled.div`
  display: flex;
  gap: 5px;
  margin-left: auto;
  width: fit-content;
`;
const Status = styled.div`
  display: flex;
  align-items: center;
  height: 16px;
  width: 16px;
  svg {
    stroke: ${(props) =>
      props.isread ? colors.purple : colors.messageNotRead};
    height: 100%;
    width: 100%;
  }
`;
const SendTime = styled.div`
  display: flex;
  align-items: center;
  p {
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    text-align: right;
    color: #fafafa4d;
  }
`;
const ReactionContainer = styled.div`
  position: absolute;
  width: 30px;
  height: 25px;
  left: ${(props) => (props.image ? "-20px" : "-12px")};
  bottom: ${(props) => (props.image ? "-15px" : "-12px")};
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: red;
  background: ${colors.chatElementBg};
`;
const ForwardedInfo = styled.div`
  display: flex;
  gap: 3px;
  align-items: center;
  margin-top: ${(props) => (props.image ? "6px" : "")};
  padding: ${(props) => (props.image ? "0 8px" : "")};
  p {
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    text-align: left;
    color: ${colors.white};
  }
  svg {
    max-height: 12px;
  }
`;
const ImageWrappr = styled.div`
  display: flex;

  img {
    display: flex;
    width: auto;
    max-width: 100%;
    cursor: pointer;
  }
`;

const VideoPreview = styled.div`
  //   max-height: 240px;
  //   min-height: 240px;
  //   display: flex;
  //   height: 100%;
  //   width: 100%;
  //   justify-content: center;
  //   align-items: center;
  //   position: relative;
  //   overflow: hidden;
  .vds-time {
    display: none;
  }
`;
const TextWrapper = styled.div`
  padding: ${(props) => (props.image ? "5px 8px" : "")};
  display: flex;

  p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre-wrap;
    word-break: break-word;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    text-align: left;
    color: ${colors.white};
  }

  i {
    font-size: 14px;
    padding: 0 5px;
  }
`;
