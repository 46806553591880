export const MESSAGES_LIST = "messages_list";
export const CALLS_LIST = "calls_list";
export const TRANSLATE = "translate";
export const AUTO = "auto";
export const MANUAL = "manual";
export const PHONE = "phone";
export const EMAIL = "email";

//MOOD CONSTANTS
export const ROMANTIC_MOOD = "romantic";
export const HUMOROUS_MOOD = "humorous";
export const CASUAL_MOOD = "casual";
export const NEUTRAL_MOOD = "neutral";
export const SUPPORTIVE_MOOD = "supportive";
export const BUSINESS_MOOD = "business";
export const FORMAL_MOOD = "formal";

export const SUBSCRIPTION_LEVEL_UNSUBSCRIBED = "not_subscribed";
export const SUBSCRIPTION_LEVEL_PRO = "pro";
export const SUBSCRIPTION_LEVEL_ULTRA = "ultra";
export const SUBSCRIPTION_LEVEL_PLATFORM = "platform";
export const SUBSCRIPTION_LEVEL_REFERRALS = "referrals";

export const ACCESS_LEVEL_PRO = "pro";
export const ACCESS_LEVEL_ULTRA = "ultra";
export const ACCESS_LEVEL_PLATFORM = "platform";

export const AUDIO_SETTINGS = "audio"
export const VIDEO_SETTINGS = "video"
export const BACKGROUND_SETTINGS = "background"

export const BLUR_BG = "blur"
export const IMAGE_BG = "image"
export const NORMAL_BG = "normal"