import React, { useRef, useState } from "react";
import styled from "styled-components";
import { colors } from "../../Assets/styles/colors";
import ReactionButton from "./ReactionButton";
import { localizationStrings } from "../../Localization/Localization";
import { ReactComponent as ForwardedIcon } from "../../Assets/icons/forwardIcon.svg";

import Linkify from "linkify-react";
import ReplymessageElement from "./ReplyMessageElement";
import FileMessageComponent from "./FileMessageComponent";

const MessageReceived = ({
    originalText,
    sentTime,
    translatedText,
    language,
    image,
    handleReactToMessage,
    messageId,
    conversationId,
    reactions,
    handleShowFullScreenImg,
    messageDeleted,
    displayRightClickMenu,
    selectedConversation,
    user,
    translatedFromLanguage,
    messageObject,
    forwarded,
    messageParent,
    file,
    video,
}) => {
    const [showReactionButton, setShowReactionButton] = useState(false);
    const [showOriginalMessage, setShowOriginalMessage] = useState(false);

    const longPressTimeout = useRef(null);

    let imageHeight = 400;
    let imageWidth = 350;


    const scaledHeightForMaxWidth = (scale) => {
        let aspectRatio = parseFloat(image.width) / parseFloat(image.height);
        let newHeight = scale / aspectRatio;
        return parseFloat(newHeight);
    };

    const scaledwidthForHeight = (scaledWidth) => {
        let aspectRatio = parseFloat(image.height) / parseFloat(image.width);
        let newWidth = scaledWidth / aspectRatio;
        return parseFloat(newWidth);
    };

    if (image) {

        if (window.innerWidth < 990) {
            imageHeight = scaledHeightForMaxWidth(300);
        } else {
            imageHeight = scaledHeightForMaxWidth(400);
        }

        imageWidth = scaledwidthForHeight(imageHeight);
    }


    //MOBILE touch context menu
    const handleTouchStart = (e) => {
        const touch = e.touches[0];
        e.preventDefault();
        // console.log(touch)
        // console.log(e, "e")
        longPressTimeout.current = setTimeout(() => {
            displayRightClickMenu(e, messageId, conversationId, messageObject)
        }, 500); // 800 ms for long press detection
    };

    const handleTouchEnd = () => {
        clearTimeout(longPressTimeout.current);
    };

    const isSimpleConversation = (conversation) => {

        if (conversation.other_participants.length == 1) {
            return true
        } else {
            return false
        }
    }


    return (
        <Container
            onMouseEnter={() => {
                if (selectedConversation?.open == false || (isSimpleConversation(selectedConversation) && selectedConversation?.other_participants[0].blocked)) {
                    return
                }
                setShowReactionButton(true);
            }}
            onMouseLeave={() => {
                setShowReactionButton(false);
            }}
        >
            <MessageWrapper
                onContextMenu={(e) =>
                    displayRightClickMenu(e, messageId, conversationId, messageObject)
                }
                onTouchStart={(e) => handleTouchStart(e)}
                onTouchEnd={handleTouchEnd}
            >
                {selectedConversation.group && (
                    <UserNameWrapper>
                        <p>{user.name}</p>
                    </UserNameWrapper>
                )}
                <ConversationAndOptions>
                    <ConversationMessage imgcontainer={image || video}>
                        <ItemWrapper >
                            {forwarded && (
                                <ForwardedInfo image={image || video}>
                                    <ForwardedIcon />
                                    <p>Forwarded</p>
                                </ForwardedInfo>
                            )}
                            {messageParent && !messageDeleted && (
                                <ReplymessageElement
                                    received
                                    text={messageParent.original_message}
                                    image={messageParent.image}
                                    messageDeleted={messageParent.deleted}
                                    sender={messageParent.user?.name}
                                    file={messageParent.document}
                                    video={messageParent.video}
                                />
                            )}

                            {image && (
                                <ImageWrapper>
                                    <img
                                        height={imageHeight}
                                        width={imageWidth}
                                        src={image.url}
                                        alt="pht"
                                        onClick={() =>
                                            handleShowFullScreenImg(
                                                image.url,
                                                messageId,
                                                conversationId
                                            )
                                        }
                                    />
                                </ImageWrapper>
                            )}

                            {video && (
                                <VideoPreview>
                                    <video
                                        src={video.video_url}
                                        width={"100%"}
                                        controls
                                    >
                                    </video>
                                </VideoPreview>
                            )}

                            {file && <FileMessageComponent file={file} />}

                            {(originalText.length > 0 ||
                                translatedText?.translation.length > 0) && (
                                    <TextWrapper image={image || video}>
                                        <Linkify options={{ target: "_blank", className: "link" }}>
                                            {showOriginalMessage ? (
                                                <p>{originalText}</p>
                                            ) : (
                                                <p>{translatedText?.translation}</p>
                                            )}
                                        </Linkify>
                                    </TextWrapper>
                                )}

                            {messageDeleted && (
                                <h3>
                                    <i>{localizationStrings.chat.message_deleted}</i>
                                </h3>
                            )}

                            {reactions[0] && !messageDeleted && (
                                <ReactionContainer>{reactions[0]?.reaction}</ReactionContainer>
                            )}
                        </ItemWrapper>
                    </ConversationMessage>

                    {showReactionButton &&
                        !messageDeleted &&
                        !selectedConversation.with_emotii && (
                            <ReactionButton
                                messageId={messageId}
                                conversationId={conversationId}
                                handleReactToMessage={handleReactToMessage}
                                setShowReactionButton={setShowReactionButton}
                            />
                        )}
                </ConversationAndOptions>

                <Details>
                    <SendTime>
                        <p>{!messageDeleted && sentTime}</p>
                    </SendTime>
                    <Status>
                        {!messageDeleted && originalText.length > 0 ? (
                            showOriginalMessage ? (
                                <p>
                                    {" "}
                                    - See{" "}
                                    <span
                                        onClick={() => setShowOriginalMessage(!showOriginalMessage)}
                                    >
                                        {localizationStrings.chat.preffered_language}
                                    </span>
                                </p>
                            ) : (
                                <p>
                                    {" "}
                                    - {localizationStrings.chat.original_in}{" "}
                                    <span
                                        onClick={() => setShowOriginalMessage(!showOriginalMessage)}
                                    >
                                        {" "}
                                        {translatedFromLanguage}
                                    </span>
                                </p>
                            )
                        ) : (
                            ""
                        )}
                    </Status>
                </Details>
            </MessageWrapper>
        </Container>
    );
};

export default MessageReceived;
const Container = styled.div`
  display: flex;
  align-items: center;
`;
const MessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  gap: 7px;
  position: relative;
  width: fit-content;
  max-width: 400px;
   @media(max-width:990px){
    max-width:300px;
  }
`;
const ConversationAndOptions = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative;
  width: fit-content;
`;

const ConversationMessage = styled.div`
  display: flex;
  flex-direction: column;
  background: ${colors.receivedMessageBg};
  padding: 16px;
  padding: ${(props) => (props.imgcontainer ? "4px" : "16px")};
  border-top-left-radius: ${(props) => (props.imgcontainer ? "4px" : "8px")};
  border-bottom-left-radius: ${(props) =>
        props.imgcontainer ? "4px" : "20px"};
  border-top-right-radius: ${(props) => (props.imgcontainer ? "4px" : "20px")};
  border-bottom-right-radius: ${(props) =>
        props.imgcontainer ? "4px" : "20px"};
  width: fit-content;
  position: relative;
  display: flex;
  align-items: center;

  h3 {
    overflow: hidden;
    text-overflow: ellipsis;
    white-spaces: pre-wrap;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    color: ${colors.chatElementColor};
    padding: 0 5px;
  }

  a {
    color: ${colors.purple};
  }
`;
const Details = styled.div`
  display: flex;
  gap: 5px;
  width: fit-content;
  align-items: start;
`;
const SendTime = styled.div`
  display: flex;
  align-items: center;

  p {
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    text-align: left;
    color: #fafafa4d;
  }
`;
const Status = styled.div`
  display: flex;
  align-items: center;

  p {
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    text-align: left;
    color: #fafafa4d;
  }
  span {
    cursor: pointer;
    font-weight: 600;
    color: ${colors.purple};
  }
`;

const ReactionContainer = styled.div`
  position: absolute;
  width: 30px;
  height: 25px;
  right: -12px;
  bottom: -12px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: red;
  background: ${colors.chatElementBg};
`;
const UserNameWrapper = styled.div`
  display: flex;
  justify-content: start;
  width: 100%;
  p {
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0px;
    text-align: left;
    color: ${colors.purple};
  }
`;
const ForwardedInfo = styled.div`
  display: flex;
  gap: 3px;
  align-items: center;
  margin-top: ${(props) => (props.image ? "6px" : "")};
  padding: ${(props) => (props.image ? "0 8px" : "")};
  p {
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    text-align: left;
    color: ${colors.white};
  }
  svg {
    max-height: 12px;
  }
`;
const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

`;
const ImageWrapper = styled.div`
  display: flex;
  img {
    display: flex;
    width: auto;
    max-width:100%;
    cursor: pointer;
  }
`;
const TextWrapper = styled.div`
  padding: ${(props) => (props.image ? "5px 8px" : "")};
  p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre-wrap;
    word-break: break-word;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    text-align: left;
    color: ${colors.white};
  }
`;
const VideoPreview = styled.div`
`;
