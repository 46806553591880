import React, { useState } from 'react'
import styled from 'styled-components'
import { colors } from '../../Assets/styles/colors'
import { ReactComponent as CloseIcon } from "../../Assets/icons/closeIcon.svg";
import { localizationStrings } from '../../Localization/Localization';
import { AUDIO_SETTINGS, BACKGROUND_SETTINGS, VIDEO_SETTINGS } from '../../Constants/constantValues';
import { ReactComponent as AudioIcon } from "../../Assets/icons/callSettings/audioSettings.svg";
import { ReactComponent as VideoIcon } from "../../Assets/icons/callSettings/videoSettings.svg";
import { ReactComponent as BackgroundIcon } from "../../Assets/icons/callSettings/backgroundSettings.svg";
import CallAudioSettings from './CallAudioSettings';
import CallVideoSettings from './CallVideoSettings';
import BackgroundVideoSettings from './BackgroundVideoSettings';
import { isSafari } from 'react-device-detect';

const CallOptionModal = ({ onClose,
    audioInputDevice,
    audioOutputDevice,
    videoInputDevice,
    backgroundType,
    setBackgoundType,
    handleVirtualBackground,
    setSelectedAudioInput,
    setSelectedAudioOutput,
    setSelectedVideoInput,
}) => {
    const [settingsTopic, setSettingsTopic] = useState(AUDIO_SETTINGS);
    const optionsList = [
        {
            icon: <AudioIcon />,
            value: AUDIO_SETTINGS,
            label: localizationStrings.call_modal.audio
        },
        {
            icon: <VideoIcon />,
            value: VIDEO_SETTINGS,
            label: localizationStrings.call_modal.video
        },
        {
            icon: <BackgroundIcon />,
            value: BACKGROUND_SETTINGS,
            label: localizationStrings.callOptionModal.background,
            exclude: isSafari

        },
    ]

    const handleSelectTopic = (value) => {
        setSettingsTopic(value)
    }

    const displayOptionTopic = () => {
        let display = <></>
        switch (settingsTopic) {
            case AUDIO_SETTINGS:
                display = <CallAudioSettings
                    audioInputDevice={audioInputDevice}
                    audioOutputDevice={audioOutputDevice}
                    setSelectedAudioInput={setSelectedAudioInput}
                    setSelectedAudioOutput={setSelectedAudioOutput}

                />
                break;
            case VIDEO_SETTINGS:
                display = <CallVideoSettings
                    videoInputDevice={videoInputDevice}
                    setSelectedVideoInput={setSelectedVideoInput}
                />
                break;
            case BACKGROUND_SETTINGS:
                display = <BackgroundVideoSettings
                    backgroundType={backgroundType}
                    setBackgoundType={setBackgoundType}
                    handleVirtualBackground={handleVirtualBackground}
                />
                break;

            default:
                break;
        }
        return display

    }

    return (
        <Overlay>
            <CardModal>
                <ListWrapper>
                    <Header>
                        <Title>
                            {localizationStrings.call_modal.settings}
                        </Title>
                    </Header>
                    <OptionsList>
                        {optionsList.map((option, index) => (
                            !option.exclude &&
                            <OptionElement
                                key={index}
                                selected={option.value === settingsTopic}
                                onClick={() => handleSelectTopic(option.value)}
                            >
                                {option.icon}
                                <p>{option.label}</p>
                            </OptionElement>

                        ))}
                    </OptionsList>
                </ListWrapper>
                <SettingsContainer>
                    <Header>
                        <CloseButton onClick={onClose}>
                            <CloseIcon />
                        </CloseButton>
                    </Header>
                    {displayOptionTopic()}
                </SettingsContainer>
            </CardModal>
        </Overlay>
    )
}

export default CallOptionModal

const Overlay = styled.div`
display:flex;
align-items:center;
justify-content:center;
position:absolute;
width:100%;
height:100%;
background:rgba(0,0,0,0.3);
left:0;
top:0;
z-index:2;
`
const CardModal = styled.div`
max-height:90vh;
max-width:90vw;
width:700px;
height:600px;
display:flex;
background:${colors.chatBg};
border-radius:5px;
border:1px solid ${colors.newButtonOptionsBg};
  box-shadow: 0 4px 20px ${colors.chatBg};
overflow:hidden;
`
const ListWrapper = styled.div`
display:flex;
flex-direction:column;
max-width:200px;
width:100%;
height:100%;
border-right:1px solid ${colors.newButtonOptionsBg};
`
const Header = styled.div`
display:flex;
width:100%;
padding:10px;
min-height:60px;
align-items:center;
`
const SettingsContainer = styled.div`
display:flex;
flex-direction:column;
width:100%;
height:100%;
overflow:auto;
`
const Title = styled.h3`
font-size:18px;
font-weight:500;
color:${colors.callControlBar.buttonLabelColor};
`
const CloseButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: none;
  cursor: pointer;
  margin-left:auto;
`;

const OptionsList = styled.div`
display:flex;
flex-direction:column;
width:100%;
height:100%;
overflow:auto;
`
const OptionElement = styled.div`
display:flex;
align-items:center;
gap:10px;
padding:10px;
cursor:pointer;
p{
font-size:14px;
line-height:16px;
font-size:500;
color:${colors.callControlBar.buttonLabelColor};
}
svg{
height:20px;
width:20px;
color:${colors.callControlBar.buttonLabelColor};
}
${props => props.selected && (
        `
border-left:2px solid ${colors.purple};
padding:10px 10px 10px 8px;
background:${colors.landingPage.semiDarkBg};
`
    )}

`