import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { colors } from "../../Assets/styles/colors";
import { ReactComponent as CloseIcon } from "../../Assets/icons/closeIcon.svg";
import { ReactComponent as Crown } from "../../Assets/icons/subscription/crown.svg";

import { useAuthService } from "../../Context/AuthContext";
import moment from "moment";
import DeleteConfirmationChild from "../GenericComponents/DeleteConfirmationChild";
import SubscriptionPlanPicker from "../Subscription/SubscriptionPlanPicker";
import SubscribedPlanCard from "../Subscription/SubscribedPlanCard";
import { localizationStrings } from "../../Localization/Localization";
import {
  ACCESS_LEVEL_ULTRA,
  SUBSCRIPTION_LEVEL_PLATFORM,
  SUBSCRIPTION_LEVEL_PRO,
  SUBSCRIPTION_LEVEL_REFERRALS,
  SUBSCRIPTION_LEVEL_ULTRA,
} from "../../Constants/constantValues";

const SubscriptionModal = ({
  handleClose,
  handleSubscribeClick,
  handleUnSubscribeClick,
  handleSetInvitations,
}) => {
  const { hadFreeTrial, checkSubscriptions, subscription, subscriptionLevel } =
    useAuthService();

  const [subscribeTo, setSubscribeTo] = useState("pro");
  const [payYearly, setPayYearly] = useState(false);

  const [userHadFreeTrial, setUserHadFreeTrial] = useState(null);

  const [openConfirmation, setOpenConfirmation] = useState(false);

  const SubscribeTitle = localizationStrings.subscription_modal.subscribe_title;
  const ProTitle = localizationStrings.subscription_modal.pro_title;
  const UltraTitle = localizationStrings.subscription_modal.ultra_title;
  const PlatformTitle = localizationStrings.subscription_modal.platform_title;

  const JoinText = localizationStrings.subscription_modal.join_text;
  const JoinedTex = localizationStrings.subscription_modal.joined_text;
  const JoinedTexUltra =
    localizationStrings.subscription_modal.joined_text_ultra;

  const navigateToLandingPageLink = (to) => {
    let url = "http://emotii.ai";
    window.open(`${url}/${to}`);
  };

  const handleHadFreeTrial = () => {
    hadFreeTrial(
      (response) => {
        const resp = response.data.had_free_trial;
        setUserHadFreeTrial(resp);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  useEffect(() => {
    handleHadFreeTrial();
    checkSubscriptions();
  }, []);

  return (
    <FullScreenItem>
      <Card>
        <Line>
          <Title>
            {subscription ? (
              <>
                <Crown style={{ height: "24px", width: "24px" }} />
                {(subscriptionLevel === SUBSCRIPTION_LEVEL_PRO ||
                  subscriptionLevel === SUBSCRIPTION_LEVEL_REFERRALS) &&
                  ProTitle}
                {subscriptionLevel === SUBSCRIPTION_LEVEL_ULTRA && UltraTitle}
                {subscriptionLevel === SUBSCRIPTION_LEVEL_PLATFORM &&
                  PlatformTitle}
              </>
            ) : (
              SubscribeTitle
            )}
          </Title>
          <CloseBtn onClick={handleClose}>
            <CloseIcon />
          </CloseBtn>
        </Line>

        <Line>
          <InfoText>
            {(subscriptionLevel === SUBSCRIPTION_LEVEL_PRO ||
              subscriptionLevel === SUBSCRIPTION_LEVEL_REFERRALS) &&
              JoinedTex}
            {subscriptionLevel === SUBSCRIPTION_LEVEL_ULTRA && JoinedTexUltra}
            {!subscription && JoinText}
          </InfoText>
        </Line>

        {subscription ? (
          <SubscribedPlanCard
            subscription={subscription}
            subscriptionLevel={subscriptionLevel}
          />
        ) : (
          <SubscriptionPlanPicker
            subscribeTo={subscribeTo}
            setSubscribeTo={setSubscribeTo}
            sendInvites={handleSetInvitations}
            payYearly={payYearly}
            setPayYearly={setPayYearly}
          />
        )}

        <Line>
          {subscription && subscription.willRenew
            ? subscription.store === "stripe" && (
                <DeleteConfirmationChild
                  action={handleUnSubscribeClick}
                  setOpen={setOpenConfirmation}
                  open={openConfirmation}
                  variant={"unsubscribe"}
                >
                  <CustomBtn onClick={() => setOpenConfirmation(true)}>
                    {localizationStrings.subscription_modal.unsubscribe}
                  </CustomBtn>
                </DeleteConfirmationChild>
              )
            : subscription?.expirationDate && (
                <InfoText style={{ color: colors.purple }}>
                  {localizationStrings.subscription_modal.active_until}{" "}
                  {moment(subscription.expirationDate).format("DD/MM/YYYY")}
                </InfoText>
              )}

          {!subscription && (
            <CustomBtn
              onClick={() => handleSubscribeClick(subscribeTo, payYearly)}
            >
              {userHadFreeTrial === false &&
                subscribeTo === SUBSCRIPTION_LEVEL_PRO &&
                localizationStrings.subscription_modal.start3Days}
              {userHadFreeTrial === false &&
                subscribeTo === SUBSCRIPTION_LEVEL_ULTRA &&
                localizationStrings.subscription_modal.start3DaysUltra}
              {userHadFreeTrial === true &&
                localizationStrings.subscription_modal.subscribe}
            </CustomBtn>
          )}
        </Line>

        <Line>
          <TermsBtn onClick={() => navigateToLandingPageLink("terms/")}>
            {localizationStrings.subscription_modal.terms}
          </TermsBtn>
        </Line>
      </Card>
    </FullScreenItem>
  );
};

export default SubscriptionModal;

const FullScreenItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  left: 0;
  top: 0;
  padding: 20px;
  z-index: 2;
`;
const Card = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 200px;
  max-width: 500px;
  width: 100%;
  max-height: 100%;
  background: ${colors.modalDarkPurple};
  border-radius: 8px;
  padding: 30px;

  position: relative;
  gap: 20px;
  overflow: auto;
`;
const Line = styled.div`
  display: flex;
  width: 100%;
  gap: 20px;
  align-items: center;
  svg {
    max-height: 40px;
    width: fit-content;
  }
`;

const Title = styled.h1`
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  color: ${colors.white};
  align-items: center;
  display: flex;
  gap: 10px;
`;

const CustomBtn = styled.button`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: ${colors.white};
  border-radius: 10px;
  border: none;
  background: ${(props) => (props.outlined ? "none" : colors.darkPurple)};
  padding: 14px;
  cursor: pointer;
  border: 1px solid ${colors.darkPurple};
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  &:hover {
    background: ${(props) =>
      props.outlined ? colors.chatsListBg : colors.purple};
    border: 1px solid ${colors.purple};
  }
`;

const CloseBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: none;
  cursor: pointer;
  margin-left: auto;
  max-width: 32px;
`;
const InfoText = styled.h1`
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  color: ${colors.chatElementColor};
`;
const SmallInfor = styled(InfoText)`
  font-size: 12px;
  line-height: 18px;
`;

const TermsBtn = styled(SmallInfor)`
  cursor: pointer;
  color: ${colors.purple};
`;
