import axios from "axios";
import LocalStorage from "../Helpers/LocalStorage";

const isElectron = !!window.electron;
const URL = process.env.REACT_APP_API_URL;

// Flag to track if a refresh is already in progress
let isRefreshing = false;
let refreshQueue = [];

const refreshTokenConfig = () => {
  return {
    method: "POST",
    url: `${URL}/api/v1/identity/users/refresh_sso_token`,
    headers: {
      platform: isElectron ? "desktop" : "web",
      Authorization: `Bearer ${LocalStorage.getAuthenticationToken()}`,
    },
    data: {
      refresh_token: LocalStorage.getRefreshToken(),
    },
  };
};

export const refreshSSOToken = (onSuccess, onError) => {
  refreshQueue.push({ onSuccess, onError });

  if (isRefreshing) {
    return;
  }

  isRefreshing = true;

  axios(refreshTokenConfig())
    .then(function (response) {
      LocalStorage.setAuthenthicationToken(response.data.token);
      LocalStorage.setRefreshToken(response.data.refresh_token);

      // Process the next request in the queue
      processQueue(true, response);
    })
    .catch(function (error) {
      // Process the next request in the queue
      processQueue(false, error);

      if (error.response) {
        const status = error.response.status;

        if (status === 401 || status === 404) {
          console.log("error", error);
          if (error?.response?.status === 404) {
            window.location.href = "#/logout-deleted-user";
          }
        }
      }
    });
};

// Process the next request in the queue
const processQueue = (success, response) => {
  refreshQueue.forEach(({ onSuccess, onError }) => {
    if (success) {
      onSuccess(response);
    } else {
      onError(response);
    }
  });

  refreshQueue.length = 0;
  isRefreshing = false;
};
