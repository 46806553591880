import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { colors } from "../../Assets/styles/colors";
import { localizationStrings } from "../../Localization/Localization";
import Select from "react-select";
import LocalStorage from "../../Helpers/LocalStorage";

const CallAudioSettings = ({ videoInputDevice, setSelectedVideoInput }) => {
  const [selectedMenuVideoInput, setSelectedMenuVideoInput] = useState(null);

  const selectedCustomStyles = {
    container: (provided) => ({
      ...provided,
      "&:focus-visible": {
        outline: "none",
      },

      fontSize: "14px",
      color: colors.newChatModalBg,
    }),
    control: (provided) => ({
      ...provided,
      "&:focus-visible": {
        outline: "none",
      },
      "&:selected": {
        outline: "none",
      },
      "&:hover": {
        boxShadow: "none",
      },

      boxShadow: "none",
      borderColor: colors.inputBg,
      background: colors.inputBg,
      color: colors.white,
      outline: "none",
    }),
    menuList: (provided) => ({
      ...provided,
      background: colors.inputBg,
      color: colors.white,
    }),
    option: (provided, { isSelected }) => ({
      ...provided,
      "&:hover": {
        background: colors.newChatModalBg,
      },
      "&:active": {
        background: colors.newChatModalBg,
      },
      "&:selected": {
        background: colors.white,
      },
      background: isSelected ? colors.darkPurple : "inherit",
    }),
    singleValue: (provided) => ({
      ...provided,
      fontSize: "14px",
      fontWeight: "500",
      color: colors.white,
    }),
  };

  const handleSyncSelectedInput = (deviceId) => {
    let found = videoInputDevice.devices.find(
      (item) => item.deviceId === deviceId
    );
    setSelectedMenuVideoInput(found);
  };

  const handleChangeInputDevice = (deviceId) => {
    videoInputDevice.setActiveMediaDevice(deviceId);
    LocalStorage.setVideoInputDevice(deviceId);
    setSelectedVideoInput(deviceId);
  };

  useEffect(() => {
    handleSyncSelectedInput(videoInputDevice.activeDeviceId);
  }, [videoInputDevice]);

  return (
    <Container>
      <Group>
        <GroupSpan>{localizationStrings.callOptionModal.camera}</GroupSpan>
        <Select
          value={selectedMenuVideoInput}
          onChange={(e) => handleChangeInputDevice(e.deviceId)}
          options={videoInputDevice.devices}
          isSearchable={false}
          isOptionSelected={(e) =>
            videoInputDevice.activeDeviceId === e.deviceId
          }
          styles={selectedCustomStyles}
        ></Select>
      </Group>
    </Container>
  );
};

export default CallAudioSettings;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0 20px;
`;
const Group = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const GroupSpan = styled.p`
  font-size: 16px;
  line-height: 18px;
  font-weight: 500;
  color: ${colors.callControlBar.buttonLabelColor};
`;
