import React, { Suspense, lazy, memo, useEffect, useState } from "react";
import styled from "styled-components";
import { colors } from "../../Assets/styles/colors";
import { ReactComponent as BackIcon } from "../../Assets/icons/arrowBack.svg";
import { ReactComponent as SearchIcon } from "../../Assets/icons/searchIcon.svg";
import { localizationStrings } from "../../Localization/Localization";
import { NEW_CONTACT, SELECTED_MEMBERS } from "./AddNewGroup";
import { ReactComponent as PlusIcon } from "../../Assets/icons/PlusIcon.svg";
import { RotatingLines, TailSpin } from "react-loader-spinner";
import _ from "lodash";
import { FixedSizeList as List } from "react-window"; // Import react-window
import FoundContactElement from "../Chat/FoundContactElement";



const AddMembers = ({
  contacts,
  handleSelectContact,
  handleInviteContact,
  selectedContacts,
  initialContacts,
  setOption,
  setIsAddNewContactOpen,
  groupDetailsSide,
  handleBackBtn,
  handleSaveBtn,
}) => {
  const [inputValues, setInputvalues] = useState({
    searchInput: "",
  });
  const [confirmDisabled, setConfirmDisabled] = useState(true);

  const handleInput = (e) => {
    const { name, value } = e.target;
    setInputvalues({
      ...inputValues,
      [name]: value,
    });
  };

  const ceckSelectedItem = (contact) => {
    let selected = false;

    if (groupDetailsSide) {
      let foundInSelected = selectedContacts.find(
        (element) => element.id === contact.id
      );
      let foundInInitial = initialContacts.find(
        (element) => element.id === contact.id
      );

      if (foundInSelected || foundInInitial) {
        selected = true;
      } else {
        selected = false;
      }
    } else {
      if (selectedContacts.find((item) => item.id === contact.id)) {
        selected = true;
      } else {
        selected = false;
      }
    }
    return selected;
  };

  const searchMember = (list) => {
    if (inputValues.searchInput === "") {
      return list;
    }
    let found = list.filter((item) =>
      item.name.toLowerCase().includes(inputValues?.searchInput?.toLowerCase())
    );
    return found;
  };

  const checkChanges = () => {
    if (_.isEqual(selectedContacts, initialContacts)) {
      setConfirmDisabled(true);
    } else {
      setConfirmDisabled(false);
    }
  };

  useEffect(() => {
    checkChanges();
  }, [selectedContacts, contacts]);

  return (
    <Wrapper>
      <Container>
        <BackButton onClick={() => handleBackBtn()}>
          <BackIcon />
          {localizationStrings.profile.back}
        </BackButton>
        <ProfileTitle>
          <h1>{localizationStrings.new_chat.add_members}</h1>
        </ProfileTitle>
        <div style={{ width: "100%", display: "flex", justifyContent: "end" }}>
          <ConfirmButton
            disabled={confirmDisabled}
            onClick={() => handleSaveBtn()}
          >
            {localizationStrings.new_chat.save}
          </ConfirmButton>
        </div>
      </Container>

      <Group>
        <TitleWrapper>
          <h1>{localizationStrings.new_chat.search_member}</h1>
        </TitleWrapper>

        <ChatOption style={{ flexDirection: "column" }}>
          <CustomSearch>
            <SearchIcon />
            <CustomInput
              name="searchInput"
              value={inputValues.searchInput}
              onChange={(e) => handleInput(e)}
              placeholder={localizationStrings.chat.search}
            />
          </CustomSearch>
        </ChatOption>
      </Group>
      <Group>
        <ChatOption style={{ flexDirection: "column" }}>
          <AddMembersButton
            onClick={() => {
              groupDetailsSide
                ? setOption(NEW_CONTACT)
                : setIsAddNewContactOpen(true);
            }}
          >
            <PlusIcon /> {localizationStrings.new_chat.new_contact}
          </AddMembersButton>
        </ChatOption>
      </Group>

      <Group>
        <TitleWrapper>
          <h1>{localizationStrings.new_chat.contacts_on_emotii}</h1>
        </TitleWrapper>
        <OptionWrapper>
          {contacts &&
            contacts.on_app_contacts?.length > 0 &&
            searchMember(contacts.on_app_contacts).map((contact) => (
              <FoundContactElement
                key={contact.id}
                item={contact}
                handleSelectContact={handleSelectContact}
                selected={ceckSelectedItem(contact)}
              />
            ))}
        </OptionWrapper>
      </Group>
      {contacts && contacts.outside_contacts?.length > 0 && (
        <Group>
          <TitleWrapper>
            <h1>{localizationStrings.new_chat.invite_to_emotii}</h1>
          </TitleWrapper>
          <OptionWrapper>

            <List
              height={600} // Adjust this based on the height of the container
              itemCount={searchMember(contacts.outside_contacts).length}
              itemSize={70} // Adjust based on the size of each contact element
              width="100%" // Full width of the container
            >
              {({ index, style }) => {
                const contact = searchMember(contacts.outside_contacts)[index];
                return (
                  <div style={style}>
                    <FoundContactElement
                      key={contact.id}
                      item={contact}
                      invite
                      handleInvite={handleInviteContact}
                    />
                  </div>
                );
              }}
            </List>

          </OptionWrapper>
        </Group>
      )}
    </Wrapper>
  );
};

export default AddMembers;

const Wrapper = styled.div`
  padding: 24px 21px;
  display: flex;
  flex-direction: column;
  gap: 25px;
`;

const Group = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${colors.newChatModalBg};
  min-height: 38px;
`;

const BackButton = styled.button`
  width: 100%;
  color: ${colors.white};
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
  background: none;
  border: none;
  cursor: pointer;
  gap: 10px;
  display: flex;
  align-items: center;
`;
const ConfirmButton = styled(BackButton)`
  color: ${colors.purple};
  margin-left: auto;
  justify-content: end;
  &:disabled {
    display: none;
  }
`;

const ProfileTitle = styled.div`
  width: 100%;
  justify-content: center;
  display: flex;
  min-width: 140px;
  h1 {
    color: ${colors.white};
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  h1 {
    color: ${colors.optionsGroupTitle};
    font-size: 12px;
    font-weight: 500;
    line-height: 22px;
    text-transform: uppercase;
  }
`;

const OptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 16px;
  background: ${colors.newChatModalInputBg};
  border-radius: 10px;
`;

const ChatOption = styled.div`
  display: flex;
  width: 100%;
  gap: 10px;
  border-bottom: 1px solid #363e5180;
  &:last-child {
    border: none;
  }
  h1 {
    min-width: 75px;
    color: #ffffff99;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.40799999237060547px;
  }
  h2 {
    text-transform: capitalize;
    color: ${colors.white};
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.40799999237060547px;
  }
`;

const CustomSearch = styled.div`
  background: ${colors.newChatModalInputBg};
  padding: 10px 16px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  width: 100%;
  gap: 10px;
`;
const CustomInput = styled.input`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: ${colors.white};
  background: none;
  width: 100%;
  border: none;
  &:focus {
    outline: none;
  }
`;
const AddMembersButton = styled.button`
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.40799999237060547px;
  color: ${colors.purple};
  border: none;
  border-radius: 10px;
  background: ${colors.newChatModalInputBg};
  padding: 14px 16px;
  cursor: pointer;
`;

const LoaderWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;
