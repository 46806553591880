import { createCable } from "@anycable/web";

class EmotiiWebSocket {
  static instance = null;

  constructor() {
    if (EmotiiWebSocket.instance) {
      return EmotiiWebSocket.instance;
    }

    this.cable = null;
    this.subscriptions = new Map(); // Track active subscriptions
    this.dataCallbacks = []; // Track callbacks for each channel
    this.subscribedChannels = new Set(); // Track channels to re-subscribe on reconnect

    EmotiiWebSocket.instance = this;
  }

  connect() {
    const endpoint = sessionStorage.getItem("wsLink");

    if (!endpoint) {
      return;
    }

    this.disconnect(); // Clean up before reconnecting

    this.cable = createCable(endpoint);

    this.cable
      .connect()
      .then(() => {
        console.log("Connected to AnyCable");
        this.#connectToChannelsIfNeeded(); // Re-subscribe to channels
      })
      .catch((error) => {
        console.error("Connection failed:", error);
        this.#handleSocketClosed();
      });

    // Listen for reconnection events
    this.cable.on("connect", () => {
      console.log("Reconnected to AnyCable");
      this.#connectToChannelsIfNeeded(); // Re-subscribe to channels
    });

    // Listen for disconnection events
    this.cable.on("disconnect", () => {
      console.log("Disconnected from AnyCable");
      this.#handleSocketClosed();
    });
  }

  disconnect() {
    if (this.cable) {
      this.cable.disconnect();
      this.cable = null;
    }
    this.#removeAllSubscriptions(); // Clean up subscriptions
  }

  subscribeToChannel(name, callback) {
    // Avoid duplicate callbacks for the same channel
    const existingCallback = this.dataCallbacks.find(
      (item) => item.name === name
    );
    if (existingCallback) {
      console.warn(`Callback already exists for channel: ${name}`);
      return;
    }

    const item = { name, callback };
    this.dataCallbacks.push(item);
    this.subscribedChannels.add(name); // Track the channel for re-subscription

    if (!this.cable || this.cable.state !== "connected") {
      return;
    }

    this.#connectToChannel(name);
  }

  sendMessage(channelName, dataObject) {
    if (!this.cable || this.cable.state !== "connected") {
      return;
    }

    const subscription = this.subscriptions.get(channelName);
    if (subscription) {
      subscription.perform("receive", dataObject);
    }
  }

  // Private methods

  #connectToChannelsIfNeeded() {
    if (!this.cable || this.cable.state !== "connected") {
      return;
    }

    // Re-subscribe to all tracked channels
    this.subscribedChannels.forEach((channelName) => {
      this.#connectToChannel(channelName);
    });
  }

  #connectToChannel(name) {
    if (!this.cable || this.cable.state !== "connected") {
      return;
    }

    // Avoid duplicate subscriptions
    if (this.subscriptions.has(name)) {
      return;
    }

    let subscription = this.cable.subscribeTo(name);
    subscription.on("message", (data) => {
      this.#handleMessageForChannel(name, data);
    });

    this.subscriptions.set(name, subscription);
  }

  #handleMessageForChannel(channel, message) {
    const item = this.dataCallbacks.find((obj) => obj.name === channel);
    if (item) {
      item.callback(channel, message);
    }
  }

  #handleSocketClosed() {
    if (!this.cable || this.cable.state === "connected") {
      return;
    }

    console.log("Socket closed, cleaning up subscriptions...");
    this.#removeAllSubscriptions(); // Clean up subscriptions on close
  }

  #removeAllSubscriptions() {
    this.subscriptions.forEach((subscription, channelName) => {
      //   // subscription.unsubscribe(); // Unsubscribe from the channel
      this.subscriptions.delete(channelName); // Remove from the subscriptions map
    });
  }
}

export default EmotiiWebSocket;
