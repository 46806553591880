import axios from "axios";
import { refreshTokenApi } from "./users/refreshToken";
import LocalStorage from "../Helpers/LocalStorage";
import { refreshSSOToken } from "./RefreshSSoTokenHelper";
const isElectron = !!window.electron;

const URL = process.env.REACT_APP_API_URL;

const buildConfig = (
  method,
  path,
  data = undefined,
  controller = null,
  onProgress = null
) => {
  var config = {
    cancelToken: controller?.token,
    method: method,
    url: `${URL}${path}`,
    headers: {
      platform: isElectron ? "desktop" : "web",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${LocalStorage.getAuthenticationToken()}`,
    },

    onUploadProgress: function (progressEvent) {
      const { loaded, total } = progressEvent;
      let precentage = Math.floor((loaded * 100) / total);
      if (onProgress != null) {
        onProgress(precentage);
      }
    },
    data: data,
  };
  return config;
};

const makeRequest = (config, onSuccess, onError, retry) => {
  axios(config)
    .then(function (response) {
      onSuccess(response);
    })
    .catch(function (error) {
      if (error.code == "ERR_CANCELED") {
        return;
      }
      let ssoFlag = LocalStorage.getSSOFlag();

      if (error?.response?.status === 401 && retry === true) {
        if (ssoFlag === "true") {
          refreshSSOToken(
            (response) => {
              let newConfig = config;
              newConfig.headers.Authorization = `Bearer ${LocalStorage.getAuthenticationToken()}`;
              makeRequest(newConfig, onSuccess, onError, retry);
            },
            (error) => {
              onError(error);
            }
          );
        } else {
          refreshTokenApi(
            LocalStorage.getRefreshToken(),
            (response) => {
              let res = JSON.parse(response);
              LocalStorage.setAuthenthicationToken(res.access_token);
              LocalStorage.setRefreshToken(res.refresh_token);
              let newConfig = config;
              newConfig.headers.Authorization = `Bearer ${LocalStorage.getAuthenticationToken()}`;
              makeRequest(newConfig, onSuccess, onError, (retry = false));
            },
            (error) => {
              onError(error);
            }
          );
        }
      } else if (error?.response?.status === 401 && retry === false) {
        window.location.href = "#/logout-deleted-user";
      } else {
        onError(error);
      }
    });
};

const makeRequestNoWithouthRedirect = (config, onSuccess, onError, retry) => {
  axios(config)
    .then(function (response) {
      onSuccess(response);
    })
    .catch(function (error) {
      if (error.code == "ERR_CANCELED") {
        console.log("ERR_CANCELED");
        return;
      }
      let ssoFlag = LocalStorage.getSSOFlag();
      if (error?.response?.status === 401 && retry === true) {
        if (ssoFlag === "true") {
          refreshSSOToken(
            (response) => {
              let newConfig = config;
              newConfig.headers.Authorization = `Bearer ${LocalStorage.getAuthenticationToken()}`;
              makeRequestNoWithouthRedirect(
                newConfig,
                onSuccess,
                onError,
                true
              );
            },
            (error) => {
              onError(error);
            }
          );
        } else {
          refreshTokenApi(
            LocalStorage.getRefreshToken(),
            (response) => {
              let res = JSON.parse(response);
              LocalStorage.setAuthenthicationToken(res.access_token);
              LocalStorage.setRefreshToken(res.refresh_token);
              let newConfig = config;
              newConfig.headers.Authorization = `Bearer ${LocalStorage.getAuthenticationToken()}`;
              makeRequestNoWithouthRedirect(
                newConfig,
                onSuccess,
                onError,
                (retry = false)
              );
            },
            (error) => {
              onError(error);
            }
          );
        }
      } else {
        onError(error);
      }
    });
};

export const GET = (
  path,
  onSuccess,
  onError,
  retry = true,
  controller = null
) => {
  controller?.cancel();
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const config = buildConfig("GET", path, undefined, source);
  makeRequest(config, onSuccess, onError, retry);

  return source;
};

export const GETWithoutRedirect = (
  path,
  onSuccess,
  onError,
  retry = true,
  controller = null
) => {
  controller?.cancel();
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const config = buildConfig("GET", path, undefined, source);
  makeRequestNoWithouthRedirect(config, onSuccess, onError, retry);

  return source;
};

export const POST = (
  path,
  data,
  onSucces,
  onError,
  retry = true,
  controller = null,
  onProgress = null
) => {
  controller?.cancel();

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  const config = buildConfig("POST", path, data, source, onProgress);
  makeRequest(config, onSucces, onError, retry);

  return source;
};
export const PATCH = (path, data, onSucces, onError, retry = true) => {
  const config = buildConfig("PATCH", path, data);
  makeRequest(config, onSucces, onError, retry);
};
export const PUT = (path, data, onSucces, onError, retry = true) => {
  const config = buildConfig("PUT", path, data);
  makeRequest(config, onSucces, onError, retry);
};
export const DELETE = (path, onSuccess, onError, retry = true) => {
  const config = buildConfig("DELETE", path);
  makeRequest(config, onSuccess, onError, retry);
};

export const DELETE_WITH_PARAMS = (
  path,
  data,
  onSuccess,
  onError,
  retry = true
) => {
  const config = buildConfig("DELETE", path, data);
  makeRequest(config, onSuccess, onError, retry);
};

export const POST_BLOB = (
  path,
  data,
  onSucces,
  onError,
  retry = true,
  controller = null,
  blob = false,
  onProgress = null
) => {
  controller?.cancel();

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  var config = buildConfig("POST", path, data, source, onProgress);
  if (blob) {
    config["responseType"] = "blob";
  }

  makeRequest(config, onSucces, onError, retry);

  return source;
};
