import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { colors } from '../../Assets/styles/colors'
import { localizationStrings } from '../../Localization/Localization'
import Select from 'react-select'
import LocalStorage from '../../Helpers/LocalStorage'

const CallAudioSettings = ({
    audioInputDevice,
    audioOutputDevice,
    setSelectedAudioInput,
    setSelectedAudioOutput
}
) => {

    const [selectedMenuAudioInput, setSelectedMenuAudioInput] = useState(null)
    const [selectedMenuAudioOutput, setSelectedMenuAudioOutput] = useState(null)

    const selectedCustomStyles = {
        container: (provided) => ({
            ...provided,
            "&:focus-visible": {
                outline: "none",
            },

            fontSize: "14px",
            color: colors.newChatModalBg,

        }),
        control: (provided) => ({
            ...provided,
            "&:focus-visible": {
                outline: "none",
            },
            "&:selected": {
                outline: "none",
            },
            "&:hover": {
                boxShadow: "none",
            },

            boxShadow: "none",
            borderColor: colors.inputBg,
            background: colors.inputBg,
            color: colors.white,
            outline: "none"
        }),
        menuList: (provided) => ({
            ...provided,
            background: colors.inputBg,
            color: colors.white,
        }),
        option: (provided, { isSelected }) => ({
            ...provided,
            "&:hover": {
                background: colors.newChatModalBg
            },
            "&:active": {
                background: colors.newChatModalBg
            },
            "&:selected": {
                background: colors.white
            },
            background: isSelected ? colors.darkPurple : "inherit"

        }),
        singleValue: (provided) => ({
            ...provided,
            fontSize: "14px",
            fontWeight: "500",
            color: colors.white,
        }),

    }

    const handleSyncSelectedInput = (deviceId) => {
        let found = audioInputDevice.devices.find((item) => item.deviceId === deviceId)
        setSelectedMenuAudioInput(found)
    }

    const handleSyncSelectedOutput = (deviceId) => {
        let found = audioOutputDevice.devices.find((item) => item.deviceId === deviceId)
        setSelectedMenuAudioOutput(found)
        if (audioOutputDevice.activeDeviceId === "") {
            let defaultDevice = audioOutputDevice.devices.find((item) => item.deviceId === "default")
            if (defaultDevice) {
                audioOutputDevice.setActiveMediaDevice(defaultDevice.deviceId)
            }
        }
    }


    const handleChangeInputDevice = (deviceId) => {
        audioInputDevice.setActiveMediaDevice(deviceId)
        LocalStorage.setInputDevice(deviceId)
        setSelectedAudioInput(deviceId)
    }

    const handleChangeOutputDevice = (deviceId) => {
        audioOutputDevice.setActiveMediaDevice(deviceId)
        LocalStorage.setOutputDevice(deviceId)
        setSelectedAudioOutput(deviceId)
    }

    useEffect(() => {
        handleSyncSelectedInput(audioInputDevice.activeDeviceId)
    }, [audioInputDevice])

    useEffect(() => {
        handleSyncSelectedOutput(audioOutputDevice.activeDeviceId)
    }, [audioOutputDevice])


    return (
        <Container>
            <Group>
                <GroupSpan>
                    {localizationStrings.callOptionModal.microphone}
                </GroupSpan>
                <Select
                    value={selectedMenuAudioInput}
                    onChange={(e) => handleChangeInputDevice(e.deviceId)}
                    options={audioInputDevice.devices}
                    isSearchable={false}
                    isOptionSelected={(e) => audioInputDevice.activeDeviceId === e.deviceId}
                    styles={selectedCustomStyles}
                >
                </Select>
            </Group>
            {audioOutputDevice.devices.length > 0 &&
                <Group>
                    <GroupSpan>
                        {localizationStrings.callOptionModal.audio_output}
                    </GroupSpan>
                    <Select
                        value={selectedMenuAudioOutput}
                        onChange={(e) => handleChangeOutputDevice(e.deviceId)}
                        options={audioOutputDevice.devices}
                        isSearchable={false}
                        isOptionSelected={(e) => audioOutputDevice.activeDeviceId === e.deviceId}
                        styles={selectedCustomStyles}
                    >
                    </Select>
                </Group>
            }
        </Container>
    )
}

export default CallAudioSettings
const Container = styled.div`
display:flex;
flex-direction:column;
gap:20px;
padding:0 20px;
`
const Group = styled.div`
display:flex;
flex-direction:column;
gap:10px;
`
const Line = styled.div`
display:flex;
gap:10px;
`
const GroupSpan = styled.p`
font-size:16px;
line-height:18px;
font-weight:500;
color:${colors.callControlBar.buttonLabelColor};
`