import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { colors } from '../../Assets/styles/colors'
import { ReactComponent as MediaIcon } from '../../Assets/icons/context-menu-icons/MediaIcon.svg'
import { ReactComponent as FileIcon } from '../../Assets/icons/context-menu-icons/FilesIcon.svg'
import { ReactComponent as SendAttachments } from '../../Assets/icons/AttachmentIcon.svg'
import { ReactComponent as SendMessageIcon } from '../../Assets/icons/sendMessageButton.svg'
import Message from './Message'
import MessageReceived from './MessageReceived'
import moment from 'moment'
import { useAuthService } from '../../Context/AuthContext'
import DateSeparator from './DateSeparator'
import EditMessageContainer from './EditMessageContainer'
import ReplyMessageContainer from './ReplyMessageContainer'
import { contextMenu } from 'react-contexify'
import InfiniteScroll from 'react-infinite-scroll-component'
import { createDateSeparators, findTranslatedTextForUser } from '../../Helpers/chatHelper/ChatHelper'
import ChatInputComponent from './ChatInputComponent'
import BlockedChatModal from './BlockedChatModal'
import { isSimpleAndBlockedConversation } from '../../Helpers/chatHelper/ConversationHelper'
import { useExpanded } from '../../hooks/useExpanded'
import MessageMoodSelector from './MessageMoodSelector'


const ConversationView = ({
    messages,
    handleSendButton,
    inputValue,
    handleInputChange,
    shouldScrollBottom,
    handleReactToMessage,
    handleShowFullScreenImg,
    handleDeleteMessage,
    displayRightClickMenu,
    mediaInputFileRef,
    scrollHeightRef,
    selectedConversation,
    setMediaInput,
    mediaInput,
    fileInputRef,
    showEditMessageModal,
    selectedMessageForContextMenu,
    handleCloseEdit,
    messageInputRef,
    showReplyMessageModal,
    handleCloseReply,
    setFileInput,
    fileInput,
    shouldLoadMoreMessages,
    handleGetMoreMessages,
    showBlockedChatModal,
    setShowBlockedChatModal,
    handleUnblockContact,
    messageMood,
    setMessageMood
}) => {
    const { user } = useAuthService();
    const messageListRef = useRef();
    const [isAttachmentMenuOpen, setIsAttachmentMenuOpen] = useState(false);
    const [showMoodSelector, togleModselector] = useExpanded()

    const getMessageToEdit = () => {
        if (!selectedMessageForContextMenu || !selectedMessageForContextMenu.messageObject) {
            return
        }

        return selectedMessageForContextMenu.messageObject
    }

    const getMessageToReply = () => {
        if (!selectedMessageForContextMenu || !selectedMessageForContextMenu.messageObject) {
            return
        }


        return selectedMessageForContextMenu.messageObject
    }




    const onConversationWrapperScroll = (e) => {
        contextMenu.hideAll();
        setIsAttachmentMenuOpen(false);
    }

    useEffect(() => {
        let element = document.getElementById("messagesList");

        if (shouldScrollBottom.current === true) {
            element.scrollTo("top", element.scrollHeight);
        }

    }, [messages, shouldScrollBottom.current,])

    let allMessages = createDateSeparators(messages);



    return (
        <Container >
            <ConversationWrapper id={"messagesList"}>
                <InfiniteScroll
                    dataLength={allMessages.length}
                    next={handleGetMoreMessages}
                    hasMore={shouldLoadMoreMessages.current}
                    onScroll={(e) => onConversationWrapperScroll(e)}
                    scrollableTarget="messagesList"
                    style={{ display: "flex", flexDirection: "column-reverse" }}
                    inverse
                >

                    {allMessages.toReversed().map((message, index) => {
                        if (message.id) {
                            const isMyMessage = user.id === message.user_id
                            if (isMyMessage) {
                                return <Message
                                    key={message.id}
                                    text={message.original_message}
                                    image={message.image}
                                    status={message.status}
                                    sentTime={moment(message.device_sent_date).format("HH:mm")}
                                    reactions={message.reactions}
                                    messageId={message.id}
                                    conversationId={message.conversation_id}
                                    handleShowFullScreenImg={handleShowFullScreenImg}
                                    handleDeleteMessage={handleDeleteMessage}
                                    messageDeleted={message.deleted}
                                    displayRightClickMenu={displayRightClickMenu}
                                    isMessageRead={message.read}
                                    messageObject={message}
                                    forwarded={message.forwarded}
                                    messageParent={message.message_parent}
                                    file={message.document}
                                    video={message.video}
                                />

                            } else {
                                return <MessageReceived
                                    key={message.id}
                                    image={message.image}
                                    messageId={message.id}
                                    conversationId={message.conversation_id}
                                    originalText={message.original_message}
                                    language={message.language_name}
                                    translatedText={findTranslatedTextForUser((message.message_translations), user)}
                                    sentTime={moment(message.device_sent_date).format("HH:mm")}
                                    handleReactToMessage={handleReactToMessage}
                                    reactions={message.reactions}
                                    handleShowFullScreenImg={handleShowFullScreenImg}
                                    messageDeleted={message.deleted}
                                    displayRightClickMenu={displayRightClickMenu}
                                    user={message.user}
                                    selectedConversation={selectedConversation}
                                    translatedFromLanguage={message.translated_from_language}
                                    messageObject={message}
                                    forwarded={message.forwarded}
                                    messageParent={message.message_parent}
                                    file={message.document}
                                    video={message.video}
                                />
                            }

                        } else {
                            return <DateSeparator key={index} date={message.date} />
                        }
                    })}




                </InfiniteScroll>
                {showBlockedChatModal &&
                    <BlockedChatModal
                        handleUnblockContact={handleUnblockContact}
                        setShowBlockedChatModal={setShowBlockedChatModal}
                        contact={selectedConversation?.other_participants[0]}
                    />
                }
            </ConversationWrapper>

            {
                showEditMessageModal &&
                <EditMessageContainer
                    message={getMessageToEdit()}
                    handleCloseEdit={handleCloseEdit}
                />
            }
            {
                showReplyMessageModal &&
                <ReplyMessageContainer
                    message={getMessageToReply()}
                    handleClose={handleCloseReply}
                    user={user}

                />
            }
            {
                showMoodSelector &&
                <MessageMoodSelector
                    isMenuOpen={showMoodSelector}
                    messageMood={messageMood}
                    closeModal={togleModselector}
                    setMood={setMessageMood}
                />
            }

            {!isSimpleAndBlockedConversation(selectedConversation) &&
                <ChatInputComponent
                    selectedConversation={selectedConversation}
                    handleSendButton={handleSendButton}
                    mediaInputFileRef={mediaInputFileRef}
                    fileInputRef={fileInputRef}
                    messageInputRef={messageInputRef}
                    setMediaInput={setMediaInput}
                    setFileInput={setFileInput}
                    isAttachmentMenuOpen={isAttachmentMenuOpen}
                    setIsAttachmentMenuOpen={setIsAttachmentMenuOpen}
                    inputValue={inputValue}
                    handleInputChange={handleInputChange}
                    showEditMessageModal={showEditMessageModal}
                    showReplyMessageModal={showReplyMessageModal}
                    handleCloseReply={handleCloseReply}
                    handleCloseEdit={handleCloseEdit}
                    togleModselector={togleModselector}
                    messageMood={messageMood}
                />
            }

        </Container >
    )
}

export default ConversationView

const Container = styled.div`
display:flex;
flex-direction:column;
background:${colors.chatBg};
height:100%;
position:relative;
// max-height:calc(100vh - 114px);
position:relative;
overflow:hidden;
@media(max-width:1440px){
    max-height:calc(100vh - 74px);
}

`

const ConversationWrapper = styled.div`
height:100%;
overflow:auto;
display:flex;
flex-direction:column-reverse;
`
