import React from 'react'
import styled from 'styled-components'
import { colors } from '../../Assets/styles/colors'
import { useNavigate } from 'react-router'
import { ReactComponent as NavLogo } from '../../Assets/icons/landingPage/nav-logo-light.svg'
const Header = () => {
    const navigate = useNavigate()
    return (
        <HeaderContainer >
            <NavLogo onClick={() => {
                navigate("/")
            }} />
        </HeaderContainer>
    )
}

export default Header

const HeaderContainer = styled.div`
display: flex;
width:100%;
justify-content: space-between;
align-items: center;
padding: 10px 120px;
min-height:40px;
margin-left: auto;
margin-right: auto;
max-width: 1440px;
h1{
    width:fit-content;
    cursor:pointer;
    color:${colors.white};
    font-family: Inter;
font-size: 32px;
font-weight: 600;
line-height: 39px;
letter-spacing: 0px;
text-align: left;
}
svg{
    cursor:pointer;
    width:80px;
}
@media (max-width: 768px) {
    padding: 40px 50px;
  }
  @media (max-width: 600px) {
    padding: 20px;
  }
`