import React, { useState } from "react";
import styled from "styled-components";
import { colors } from "../../Assets/styles/colors";
import { autoUpdate, flip, FloatingFocusManager, offset, shift, useClick, useDismiss, useFloating, useInteractions, useRole, useId } from "@floating-ui/react";
import { localizationStrings } from "../../Localization/Localization";

const CustomSelectItem = ({ label, value, selectedMetrics, onClick }) => {
  return (
    <CustomListElementContainer onClick={onClick}>
      <p>{label}</p>
      <input readOnly checked={selectedMetrics} type="checkbox" />
    </CustomListElementContainer>
  );
};


const OptionsControlButton = ({
  activeIcon,
  inactiveIcon,
  active,
  label,
  devices,
  selectedDevices,
  handleOpenOptionModal,
  ...props
}) => {


  const [isOpen, setIsOpen] = useState(false);

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware: [
      offset(10),
      flip({ fallbackAxisSideDirection: "end" }),
      shift(),
    ],
    whileElementsMounted: autoUpdate,
  });

  const click = useClick(context);
  const dismiss = useDismiss(context);
  const role = useRole(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([
    click,
    dismiss,
    role,
  ]);

  const headingId = useId();


  return (
    <Container>
      <Wrapper>
        <CustomButton
          {...props}
          $active={active}
          ref={refs.setReference}
          {...getReferenceProps()}
          onClick={handleOpenOptionModal}
        >
          {active ? activeIcon : inactiveIcon}
        </CustomButton>
        <CustomLabel>{label}</CustomLabel>
      </Wrapper>
      {/* {isOpen && (
        <FloatingFocusManager context={context} modal={false}>
          <MetricsList
            ref={refs.setFloating}
            style={floatingStyles}
            aria-labelledby={headingId}
            {...getFloatingProps()}
          >
            <CategoryText>
              {localizationStrings.callOptionModal.microphone}
            </CategoryText>
            {audioInputDevice.devices.map((metric, index) => (
              <CustomSelectItem
                key={index}
                label={metric.label}
                value={metric.deviceId}
                selectedMetrics={audioInputDevice.activeDeviceId === metric.deviceId}
                onClick={(e) => audioInputDevice.setActiveMediaDevice(metric.deviceId)}
              />
            ))}
            {videoInputDevice.devices.length > 0 &&
              <CategoryText>
                {localizationStrings.callOptionModal.camera}
              </CategoryText>
            }
            {videoInputDevice.devices.map((metric, index) => (
              <CustomSelectItem
                key={index}
                label={metric.label}
                value={metric.deviceId}
                selectedMetrics={videoInputDevice.activeDeviceId === metric.deviceId}
                onClick={(e) => videoInputDevice.setActiveMediaDevice(metric.deviceId)}
              />
            ))}
            {audioOutputDevice.devices.length > 0 &&
              <CategoryText>
                {localizationStrings.callOptionModal.speaker}
              </CategoryText>
            }
            {audioOutputDevice.devices.map((metric, index) => (
              <CustomSelectItem
                key={index}
                label={metric.label}
                value={metric.deviceId}
                selectedMetrics={audioOutputDevice.activeDeviceId === metric.deviceId}
                onClick={(e) => audioOutputDevice.setActiveMediaDevice(metric.deviceId)}
              />
            ))}
          </MetricsList>
        </FloatingFocusManager>
      )} */}

    </Container>
  );
};

export default OptionsControlButton;

const Container = styled.div`
  position: relative;
`
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  min-height: 43px;
  align-items: center;
  gap: 5px;
`;
const CustomButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 50px;
  border-radius: 100%;
  border: none;
  cursor: pointer;
  overflow: hidden;
  background: transparent;
`;

const CustomLabel = styled.label`
  font-size: 10px;
  color: ${colors.callControlBar.buttonLabelColor};
  line-height: 16px;
  text-align: center;
`;


const MetricsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 15px 10px;
  border-radius: 5px;
  border: 1px solid ${colors.purple};
  background: ${colors.background};
  width: 100%;
  min-width: 300px;
  z-index: 5;
`;

const CustomListElementContainer = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  color: ${colors.callControlBar.buttonLabelColor};
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  input {
    margin-left: auto;
  }
`;


const CategoryText = styled.h3`
font-size:14px;
font-weight:500;
color:${colors.purple};
`