class LocalStorage {
  static setAuthenthicationToken(access_token) {
    localStorage.setItem("access_token", access_token);
  }
  static getAuthenticationToken() {
    return localStorage.getItem("access_token");
  }
  static setRefreshToken(refresh_token) {
    localStorage.setItem("refresh_token", refresh_token);
  }
  static setSSOFlag(flag) {
    localStorage.setItem("sso_auth", flag);
  }
  static removeRefreshToken() {
    localStorage.removeItem("refresh_token");
  }
  static removeAuthenticationToken() {
    localStorage.removeItem("access_token");
  }
  static removeSSOFlag() {
    localStorage.removeItem("sso_auth");
  }
  static getRefreshToken() {
    return localStorage.getItem("refresh_token");
  }
  static setAppLanguage(app_language) {
    localStorage.setItem("emotii_language", app_language);
  }
  static getAppLanguage() {
    return localStorage.getItem("emotii_language");
  }
  static setWsLink(websocket_link) {
    localStorage.setItem("websocket_link", websocket_link);
  }
  static getWsLink() {
    return localStorage.getItem("websocket_link");
  }
  static setQcWsLink(websocket_link) {
    localStorage.setItem("qc_websocket_link", websocket_link);
  }
  static getQcWsLink() {
    return localStorage.getItem("qc_websocket_link");
  }
  static setRatingCounter(counter) {
    localStorage.setItem("rating_counter", counter);
  }
  static getRatingCounter() {
    return localStorage.getItem("rating_counter");
  }
  static getSSOFlag() {
    return localStorage.getItem("sso_auth");
  }
  static getFirstMessageRead() {
    return localStorage.getItem("first_use_message_read");
  }
  static getTryProCard() {
    return localStorage.getItem("try_pro_subscription_card");
  }
  static setFirstMessageRead(first_message_read) {
    return localStorage.setItem("first_use_message_read", first_message_read);
  }
  static setTryProCard(date) {
    return localStorage.setItem("try_pro_subscription_card", date);
  }
  static getFirstWelcomeMessage() {
    return localStorage.getItem("first_welcome_message_read");
  }
  static setFirstWelcomeMessageRead(first_message_read) {
    return localStorage.setItem(
      "first_welcome_message_read",
      first_message_read
    );
  }
  static setInvitedBy(invited_by) {
    return localStorage.setItem("invited_by", invited_by);
  }
  static getInvitedBy() {
    return localStorage.getItem("invited_by");
  }
  static removeInvitedBy() {
    localStorage.removeItem("invited_by");
  }
  static setCallVirtualBg(bg) {
    return localStorage.setItem("call_virtual_bg", bg);
  }
  static getCallVirtualBg(bg) {
    return localStorage.getItem("call_virtual_bg");
  }

  static setOutputDevice(deviceId) {
    return localStorage.setItem("output_device_id", deviceId);
  }
  static getOutputDevice() {
    return localStorage.getItem("output_device_id");
  }
  static setInputDevice(deviceId) {
    return localStorage.setItem("input_device_id", deviceId);
  }
  static getInputDevice() {
    return localStorage.getItem("input_device_id");
  }
  static setVideoInputDevice(deviceId) {
    return localStorage.setItem("video_input_device_id", deviceId);
  }
  static getVideoInputDevice() {
    return localStorage.getItem("video_input_device_id");
  }
}

export default LocalStorage;
