
import React, { useState } from 'react'
import styled from 'styled-components'
import { Popover } from 'react-tiny-popover'
import { ReactComponent as SendAttachments } from '../../Assets/icons/AttachmentIcon.svg'
import { ReactComponent as SendMessageIcon } from '../../Assets/icons/sendMessageButton.svg'
import { ReactComponent as FileIcon } from '../../Assets/icons/context-menu-icons/FilesIcon.svg'
import { ReactComponent as MediaIcon } from '../../Assets/icons/context-menu-icons/MediaIcon.svg'
import AttachmentMenu from '../Chat/AttachmentMenu'
import { localizationStrings } from '../../Localization/Localization'
import { colors } from '../../Assets/styles/colors'
import { isMobile, isSafari } from 'react-device-detect'
import MessageMood from '../Chat/MessageMood'
import { TextareaAutosize } from '@mui/material'


const QcInputLine = ({
    mediaInputFileRef, fileInputRef, messageInputRef,
    handleMediaInputFileChange, handleInputFileChange,
    inputValue, setInputValue,
    isAttachmentMenuOpen, setIsAttachmentMenuOpen,
    handleOpenUploadMedia, handleOpenUploadFile,
    showEditMessageModal, handleCloseEdit,
    showReplyMessageModal, handleCloseReply,
    handleSendButton,
    onKeyDown,
    togleModselector,
    messageMood
}) => {
    const [expanded, setExpanded] = useState(false);

    var fileInputAccept = "application/*"

    if (isMobile) {
        fileInputAccept = ".jpeg, .jpg, .pdf, .doc, .docx, .xlsx, .xlsx, .txt, .mp4, .mov"
    }

    const attachmentOptionsList = [
        {
            text: "Media",
            icon: <MediaIcon />,
            action: () => handleOpenUploadMedia(),
        },
        {
            text: "Files",
            icon: <FileIcon />,
            action: () => handleOpenUploadFile(),
        },
    ]


    const handleEsc = (e) => {
        if (showEditMessageModal) {
            if (e.key === "Escape") {
                handleCloseEdit();
            }

        }

        if (showReplyMessageModal) {
            if (e.key === "Escape") {
                handleCloseReply()
            }
        }


    }

    //CHANGE HEADER POSITION -> Defined here because inputField is last rendered item

    if (isMobile && isSafari) {
        let initialViewportHeight = window.innerHeight;
        let inputField = document.getElementById('message-input');
        let header = document.getElementById("qc-header")
        inputField?.addEventListener('focus', function () {

            setTimeout(() => {
                if (window.innerHeight < initialViewportHeight) {
                    // console.log(initialViewportHeight - window.innerHeight);

                    header.style.top = `${initialViewportHeight - window.innerHeight}px`;

                } else {
                    // header.style.top = 0;

                }

            }, 100); // Delay to account for potential keyboard animation

        });

        inputField?.addEventListener('blur', function () {
            setTimeout(() => {
                if (window.innerHeight === initialViewportHeight) {
                    header.style.top = `0`;

                }
            }, 100); // Delay to account for potential keyboard animation
        });

        window.addEventListener('orientationchange', function () {
            initialViewportHeight = window.innerHeight;
        });
    }

    const handleAutoExpandInputChange = (e) => {
        setInputValue(e.currentTarget.value);
        setExpanded(e.target.scrollHeight > 42);
    };



    return (
        <Container onKeyDown={(e) => onKeyDown(e)}>
            <input
                type="file"
                hidden
                ref={mediaInputFileRef}
                onChange={(e) => handleMediaInputFileChange(e)}
                accept=".png, .jpg, .jpeg, .mp4, "
            />
            <input
                type="file"
                hidden
                ref={fileInputRef}
                onChange={(e) => handleInputFileChange(e)}
                accept={fileInputAccept}

            />

            <Popover
                isOpen={isAttachmentMenuOpen}
                onClickOutside={() => setIsAttachmentMenuOpen(false)}
                positions={["top"]}
                padding={10}
                transform={{ left: 70, }}
                transformMode='relative'
                content={
                    <AttachmentMenu
                        optionsList={attachmentOptionsList}
                    />
                }
            >
                <AttachmentButton onClick={(e) => setIsAttachmentMenuOpen(true)}>
                    <SendAttachments />
                </AttachmentButton>
            </Popover>

            <TextareaAutosize
                onKeyDown={(e) => handleEsc(e)}
                ref={messageInputRef}
                placeholder={localizationStrings.chat.type_your_message}
                value={inputValue}
                onChange={(e) => handleAutoExpandInputChange(e)}
                maxRows={4}
                minRows={1}
                style={{
                    width: "100%",
                    padding: "10px 16px",
                    border: "none",
                    background: "#d9d9d90d",
                    resize: "none",
                    color: "white",
                    fontSize: "16px",
                    fontWeight: "400",
                    lineHeight: "22px",
                    textAlign: "left",
                    overflowY: "hidden",
                    wordBreak: "break-word",
                    transition:
                        "border-radius 0.2s ease-in-out, height 0.2s ease-in-out",
                    borderRadius: expanded ? "20px" : "100px",
                    outline: "none", // Handle outline on focus
                }}
            />
            <RightEndWrapper>
                <MessageMood
                    onClick={togleModselector}
                    mood={messageMood}
                />
                <SendMessageButton disabled={inputValue?.length == 0} onClick={() => handleSendButton()}>
                    <SendMessageIcon />
                </SendMessageButton>
            </RightEndWrapper>
        </Container>
    )
}

export default QcInputLine

const Container = styled.div`
  display: flex;
  background: ${colors.userMenuHeaderBg};
  padding: 16px;
  height: auto;
  gap: 12px;
  align-items: end;

`
const AttachmentButton = styled.button`
width:42px;
height:42px;
border-radius:100%;
display:flex;
jusitfy-content:center;
align-items:center;
cursor:pointer;
border:none;
background:none;
`

const MessageInput = styled.input`
width:100%;
border-radius:100px;
padding:10px 16px;
border:none;
background:#D9D9D90D;
color:${colors.white};
font-size: 16px;
font-weight: 400;
line-height: 22px;
text-align: left;
&:focus{
outline:none;
}
`

const SendMessageButton = styled.button`
border-radius:100%;
display:flex;
display:flex;
min-width:42px;
max-width:42px;
width:100%;
align-items:center;
cursor:pointer;
border:none;
background:none;
&:disabled{
opacity:0.5;
cursor:inherit;
}

`
const RightEndWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;
