import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { colors } from "../../Assets/styles/colors";
import { notifyError } from "../../Helpers/Notifications";
import { ReactComponent as MediaIcon } from "../../Assets/icons/context-menu-icons/MediaIcon.svg";
import { ReactComponent as FileIcon } from "../../Assets/icons/context-menu-icons/FilesIcon.svg";
import { ReactComponent as SendAttachments } from "../../Assets/icons/AttachmentIcon.svg";
import { ReactComponent as SendMessageIcon } from "../../Assets/icons/sendMessageButton.svg";
import { Popover } from "react-tiny-popover";
import AttachmentMenu from "./AttachmentMenu";
import { localizationStrings } from "../../Localization/Localization";
import ClosedQuickChatInfo from "../QuickChat/ClosedQuickChatInfo";
import MessageMood from "./MessageMood";
import TextareaAutosize from "react-textarea-autosize";
import { useState } from "react";

const ChatInputComponent = ({
  selectedConversation,
  handleSendButton,
  mediaInputFileRef,
  fileInputRef,
  messageInputRef,
  setMediaInput,
  setFileInput,
  isAttachmentMenuOpen,
  setIsAttachmentMenuOpen,
  inputValue,
  handleInputChange,
  showEditMessageModal,
  showReplyMessageModal,
  handleCloseReply,
  handleCloseEdit,
  togleModselector,
  messageMood,
}) => {
  const [expanded, setExpanded] = useState(false);

  const attachmentOptionsList = [
    {
      text: "Media",
      icon: <MediaIcon />,
      action: () => handleOpenUploadMedia(),
    },
    {
      text: "Files",
      icon: <FileIcon />,
      action: () => handleOpenUploadFile(),
    },
  ];

  const handleEsc = (e) => {
    if (showEditMessageModal) {
      if (e.key === "Escape") {
        handleCloseEdit();
      }
    }

    if (showReplyMessageModal) {
      if (e.key === "Escape") {
        handleCloseReply();
      }
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault(); // Prevents a new line
      handleSendButton(); // Sends the message
    }
  };
  const handleOpenUploadMedia = () => {
    mediaInputFileRef.current.click();
  };
  const handleOpenUploadFile = () => {
    fileInputRef.current.click();
  };

  const handleMediaInputFileChange = (e) => {
    if (!e.target.files) {
      return;
    }

    const inputFile = e.target.files[0];
    const fileType = inputFile.type.split("/");
    if (fileType[0] == "image" || fileType[0] == "video") {
      setMediaInput(inputFile);
    } else {
      notifyError("Please select valid file");
      return;
    }
  };

  const handleInputFileChange = (e) => {
    if (!e.target.files) {
      return;
    }

    const inputFile = e.target.files[0];
    setFileInput(inputFile);
  };

  const handleAutoExpandInputChange = (e) => {
    handleInputChange(e);
    setExpanded(e.target.scrollHeight > 42);
  };



  return (
    <div>
      {selectedConversation.quick_chat &&
        selectedConversation.open === false ? (
        <>
          <ClosedQuickChatInfo />
        </>
      ) : (
        <>
          {!selectedConversation.with_emotii && (
            <MessageInputWrapper onKeyDown={(e) => handleKeyDown(e)}>
              <input
                type="file"
                hidden
                ref={mediaInputFileRef}
                onChange={(e) => handleMediaInputFileChange(e)}
                accept="image/*, video/* "
              />
              <input
                type="file"
                hidden
                ref={fileInputRef}
                onChange={(e) => handleInputFileChange(e)}
                accept="application/*"
              />

              <Popover
                isOpen={isAttachmentMenuOpen}
                onClickOutside={() => setIsAttachmentMenuOpen(false)}
                positions={["top"]}
                padding={10}
                transform={{ left: 70 }}
                transformMode="relative"
                content={<AttachmentMenu optionsList={attachmentOptionsList} />}
              >
                <AttachmentButton
                  onClick={(e) => setIsAttachmentMenuOpen(true)}
                >
                  <SendAttachments />
                </AttachmentButton>
              </Popover>

              <TextareaAutosize
                onKeyDown={(e) => handleEsc(e)}
                ref={messageInputRef}
                placeholder={localizationStrings.chat.type_your_message}
                value={inputValue}
                onChange={(e) => handleAutoExpandInputChange(e)}
                maxRows={4}
                minRows={1}
                style={{
                  width: "100%",
                  padding: "10px 16px",
                  border: "none",
                  background: "#d9d9d90d",
                  resize: "none",
                  color: "white",
                  fontSize: "16px",
                  fontWeight: "400",
                  lineHeight: "22px",
                  textAlign: "left",
                  overflowY: "hidden",
                  wordBreak: "break-word",
                  transition:
                    "border-radius 0.2s ease-in-out, height 0.2s ease-in-out",
                  borderRadius: expanded ? "20px" : "100px",
                  outline: "none", // Handle outline on focus
                }}
              />

              <RightEndWrapper>
                <MessageMood onClick={togleModselector} mood={messageMood} />

                <SendMessageButton
                  disabled={inputValue.length == 0}
                  onClick={() => handleSendButton()}
                >
                  <SendMessageIcon />
                </SendMessageButton>
              </RightEndWrapper>
            </MessageInputWrapper>
          )}
        </>
      )}
    </div>
  );
};

export default ChatInputComponent;

const MessageInputWrapper = styled.div`
  display: flex;
  background: ${colors.userMenuHeaderBg};
  padding: 16px;
  height: 74px;
  min-height: 74px;
  // max-height:74px;
  height: 100%;
  gap: 12px;
  align-items: end;
`;
const AttachmentButton = styled.button`
  width: 42px;
  height: 42px;
  border-radius: 100%;
  display: flex;
  jusitfy-content: center;
  align-items: center;
  cursor: pointer;
  border: none;
  background: none;
`;
const SendMessageButton = styled.button`
  width: 42px;
  height: 42px;
  border-radius: 100%;
  display: flex;
  jusitfy-content: center;
  align-items: center;
  cursor: pointer;
  border: none;
  background: none;
  &:disabled {
    opacity: 0.5;
    cursor: inherit;
  }
`;
// const MessageInput = styled.textarea`
// width:100%;
// border-radius:100px;
// padding:10px 16px;
// border:none;
// background:#D9D9D90D;
// resize:none;
// color:${colors.white};
// font-size: 16px;
// font-weight: 400;
// line-height: 22px;
// text-align: left;
// &:focus{
// outline:none;
// }
// `

const MessageInput = styled.textarea`
  width: 100%;
  padding: 10px 16px;
  border: none;
  background: #d9d9d90d;
  resize: none;
  color: white;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  min-height: 42px;
  height: 42px;
  max-height: 200px;
  overflow-y: hidden;
  word-break: break-word;
  transition: border-radius 0.2s ease-in-out, height 0.2s ease-in-out;
  border-radius: 100px;
  &.expanded {
    border-radius: 20px; /* Smaller when expanded */
  }

  &:focus {
    outline: none;
  }
`;

const RightEndWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;
