import { POST } from "../API";

export const markMessageAsReadApi = (
  conversationId,
  messageId,
  onSuccess,
  onError
) => {
  const data = {};
  POST(
    `/api/v1/chat/conversations/${conversationId}/messages/${messageId}/read`,
    data,
    onSuccess,
    onError,
    true
  );
};
