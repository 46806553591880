import React from 'react'
import styled from 'styled-components';
import { colors } from '../../Assets/styles/colors';
import { ReactComponent as CloseIcon } from "../../Assets/icons/closeIcon.svg";
import { localizationStrings } from '../../Localization/Localization';
const ChatDetailsHeader = ({ selectedConversation, handleClose }) => {

    return (
        <Container >
            <Text>
                <h1>{localizationStrings.chat_details.details}</h1>
            </Text>

            <CloseBtn onClick={handleClose}>
                <CloseIcon />
            </CloseBtn>
        </Container>
    )
}

export default ChatDetailsHeader
const Container = styled.div`
display:flex;
align-items:center;
gap:15px;
padding: 16px;
min-height:74px;
background:${colors.newChatModalBg};
`

const Text = styled.div`
  display: flex;
  flex-direction: column;
  gap: px;
overflow:hidden;
h1{
  text-transform:capitalize;
    color:${colors.white};
font-size: 20px;
font-weight: 500;
line-height: 30px;
text-align: left;

}
`;

const CloseBtn = styled.button`
display:flex;
align-items:center;
justify-content:center;
border:none;
background:none;
cursor:pointer;
margin-left:auto;
`