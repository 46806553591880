import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { colors } from '../../Assets/styles/colors'
import { localizationStrings } from '../../Localization/Localization'
import { BLUR_BG, IMAGE_BG, NORMAL_BG } from '../../Constants/constantValues'
import LocalStorage from '../../Helpers/LocalStorage'
import img1 from "../../Assets/img/1.jpeg"
import img2 from "../../Assets/img/2.jpeg"
import img3 from "../../Assets/img/3.jpeg"
import img4 from "../../Assets/img/4.jpeg"
import img5 from "../../Assets/img/5.jpeg"

const BackgroundVideoSettings = ({ backgroundType, setBackgoundType, handleVirtualBackground }) => {

    const imagesBg = [img1, img2, img3, img4, img5]

    const initializeBgFromLocalStorage = () => {
        let bgType = LocalStorage.getCallVirtualBg();
        if (bgType) {
            setBackgoundType(bgType)
        }
    }

    useEffect(() => {
        initializeBgFromLocalStorage()
    }, [])

    return (
        <Container>
            <Group>
                <GroupSpan>
                    <BackgroundList>
                        <BgElement onClick={() => handleVirtualBackground(NORMAL_BG, null)} selected={backgroundType === NORMAL_BG}>
                            <p>{localizationStrings.callOptionModal.none}</p>
                        </BgElement>
                        <BlurElement onClick={() => handleVirtualBackground(BLUR_BG, null)} selected={backgroundType === BLUR_BG}>
                            <p>{localizationStrings.callOptionModal.blur}</p>
                        </BlurElement>
                        {
                            imagesBg.map((img, index) => (
                                <ImgElement
                                    key={index}
                                    onClick={() => handleVirtualBackground(IMAGE_BG, img)}
                                    selected={backgroundType === img}>
                                    <img src={img} alt={img + "alt"} />
                                </ImgElement>
                            ))
                        }
                    </BackgroundList>
                </GroupSpan>
            </Group>
        </Container>
    )
}

export default BackgroundVideoSettings
const Container = styled.div`
display:flex;
flex-direction:column;
gap:20px;
padding:0 20px;
`
const Group = styled.div`
display:flex;
flex-direction:column;
gap:10px;
`

const GroupSpan = styled.p`
font-size:16px;
line-height:18px;
font-weight:500;
color:${colors.callControlBar.buttonLabelColor};
`
const BackgroundList = styled.div`
display:flex;
flex-wrap:wrap;
gap:10px;

`
const BgElement = styled.div`
display:flex;
align-items:center;
justify-content:center;
background:${colors.newButtonOptionsBg};
width:140px;
height:100px;
border-radius:5px;
cursor:pointer;
overflow:hidden;
${props => props.selected && `border:1px solid ${colors.darkPurple};`};
`
const BlurElement = styled(BgElement)`
 background: ${colors.modalInfoTextColor};
box-shadow: inset 0px 0px 50px -3px rgba(0,0,0,0.75);
`
const ImgElement = styled(BgElement)`
img{
width:100%;
height:100%;
}
`