import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { colors } from "../../Assets/styles/colors";
import { benefitsList } from "./SubscriptionPlanPicker";
import { ReactComponent as CheckSvg } from "../../Assets/icons/subscription/check.svg";
import moment from "moment";
import { localizationStrings } from "../../Localization/Localization";
import {
  SUBSCRIPTION_LEVEL_PLATFORM,
  SUBSCRIPTION_LEVEL_PRO,
  SUBSCRIPTION_LEVEL_REFERRALS,
  SUBSCRIPTION_LEVEL_ULTRA,
} from "../../Constants/constantValues";

const BenefitElement = ({ title, subtitle }) => {
  return (
    <BenefitWrapper>
      <TextWrapper>
        <Title>{title}</Title>
        <Subtitle>{subtitle}</Subtitle>
      </TextWrapper>
      <CheckWrapper>
        <CheckSvg />
      </CheckWrapper>
    </BenefitWrapper>
  );
};

const SubscribedPlanCard = ({ subscription, subscriptionLevel }) => {
  const [price, setPrice] = useState("");
  const [pricing, setPricing] = useState("");
  const [referralPrice, setReferralPrice] = useState(false);
  const [priceType, setPriceType] = useState("subscription");

  const proBenefits = [
    {
      title: localizationStrings.subscription_modal.benefit1Title,
      description: localizationStrings.subscription_modal.benefit1Description,
    },
    {
      title: localizationStrings.subscription_modal.benefit2Title,
      description: localizationStrings.subscription_modal.benefit2Description,
    },
    {
      title: localizationStrings.subscription_modal.benefit3Title,
      description: localizationStrings.subscription_modal.benefit3Description,
    },
    {
      title: localizationStrings.subscription_modal.benefit4Title,
      description: localizationStrings.subscription_modal.benefit4Description,
    },
  ];

  const ultraBenefits = [
    {
      title: localizationStrings.subscription_modal.benefit1Title,
      description: localizationStrings.subscription_modal.benefit1Description,
    },
    {
      title: localizationStrings.subscription_modal.benefit2Title,
      description: localizationStrings.subscription_modal.benefit2Description,
    },
    {
      title: localizationStrings.subscription_modal.benefit3Title,
      description: localizationStrings.subscription_modal.benefit3Description,
    },
    {
      title: localizationStrings.subscription_modal.benefit4Title,
      description: localizationStrings.subscription_modal.benefit4Description,
    },
    {
      title: localizationStrings.subscription_modal.benefit5Title,
      description: localizationStrings.subscription_modal.benefit5Description,
    },
  ];

  const platformBenefits = [
    {
      title: localizationStrings.subscription_modal.benefit1Title,
      description: localizationStrings.subscription_modal.benefit1Description,
    },
    {
      title: localizationStrings.subscription_modal.benefit2Title,
      description: localizationStrings.subscription_modal.benefit2Description,
    },
    {
      title: localizationStrings.subscription_modal.benefit3Title,
      description: localizationStrings.subscription_modal.benefit3Description,
    },
    {
      title: localizationStrings.subscription_modal.benefit4Title,
      description: localizationStrings.subscription_modal.benefit4Description,
    },
    {
      title: localizationStrings.subscription_modal.benefit5Title,
      description: localizationStrings.subscription_modal.benefit5Description,
    },
    {
      title: localizationStrings.subscription_modal.benefit6Title,
      description: localizationStrings.subscription_modal.benefit6Description,
    },
  ];

  const [benefits, setBenefits] = useState(proBenefits);

  let willRenew = subscription.willRenew;
  let expirationDate = subscription.expirationDate?.toString();
  let nextPayment = moment(expirationDate).format("DD.MM.YYYY");
  let productId = subscription.productIdentifier;

  const initializeSubscription = (productId) => {
    if (subscriptionLevel === SUBSCRIPTION_LEVEL_PLATFORM) {
      if (subscription.active && subscription.status === "active") {
        setPriceType("platform");
        setPrice(subscription.project.name);
        setReferralPrice("platform");
        setBenefits(platformBenefits);
        return;
      }
    }

    setReferralPrice(false);
    setPrice("");

    if (subscriptionLevel === SUBSCRIPTION_LEVEL_PRO) {
      setBenefits(proBenefits);
    }

    if (subscriptionLevel === SUBSCRIPTION_LEVEL_ULTRA) {
      setBenefits(ultraBenefits);
    }


    if (subscriptionLevel === SUBSCRIPTION_LEVEL_REFERRALS) {
      setPriceType("referral");
      setPrice(localizationStrings.subscribed_plan_card.one_month_free);
      setReferralPrice("referral");
    }

    switch (productId) {
      //PRO MONTHLY-staging
      case "prod_QGzOUUfUj5dpQr":
        setPrice("0.99");
        setPricing(localizationStrings.subscribed_plan_card.monthly);
        setReferralPrice(false);
        break;
      //PRO MONTHLY - prod
      case "prod_QMul12YakUO7gP":
        setPrice("0.99");
        setPricing(localizationStrings.subscribed_plan_card.monthly);
        setReferralPrice(false);
        break;

      //PRO YEARLY -staging
      case "prod_R3Mf48xB1kH99u":
        setPrice("9.99");
        setPricing(localizationStrings.subscribed_plan_card.yearly);
        setReferralPrice(false);
        break;

      //PRO YEARLY - prod
      case "prod_R3Mo2q1e3ec0Kh":
        setPrice("9.99");
        setPricing(localizationStrings.subscribed_plan_card.yearly);
        setReferralPrice(false);
        break;

      //ULTRA MONTHLY - staging
      case "prod_RccoCUA6PAGyl0":
        setPrice("4.99");
        setPricing(localizationStrings.subscribed_plan_card.monthly);
        setReferralPrice(false);
        setBenefits(ultraBenefits);
        break;

      //ULTRA MONTHLY - prod
      case "prod_RccsdQc1QkkV4e":
        setPrice("4.99");
        setPricing(localizationStrings.subscribed_plan_card.monthly);
        setReferralPrice(false);
        setBenefits(ultraBenefits);
        break;

      //ULTRA YEARLY - staging
      case "prod_RccoSZgXRaghZN":
        setPrice("49.99");
        setPricing(localizationStrings.subscribed_plan_card.yearly);
        setReferralPrice(false);
        setBenefits(ultraBenefits);
        break;
      //ULTRA YEARLY - prod
      case "prod_RcctyFAULlxMGw":
        setPrice("49.99");
        setPricing(localizationStrings.subscribed_plan_card.yearly);
        setReferralPrice(false);
        setBenefits(ultraBenefits);
        break;

      case "subscription_from_platform":
        if (subscription.active && subscription.status === "active") {
          setPriceType("platform");
          setPrice(subscription.project.name);
          setReferralPrice("platform");

        } else {
          setPrice(localizationStrings.subscribed_plan_card.one_month_free);
          setPriceType("refferal");
        }
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    initializeSubscription(productId);
  }, [subscription]);

  return (
    <Wrapper>
      <Price>
        {referralPrice === "platform" && (
          <RefferalPriceInfo>
            <span>{localizationStrings.subscribed_plan_card.platform}</span>
            <br />
            {price}
          </RefferalPriceInfo>
        )}

        {referralPrice === "referral" && (
          <RefferalPriceInfo>
            <span>{localizationStrings.subscribed_plan_card.refferal}</span>
            <br />
            {price}
          </RefferalPriceInfo>
        )}

        {priceType === "subscription" && (
          <PriceInfo>
            {price && `$${price}`} {pricing && <span>/{pricing}</span>}
          </PriceInfo>
        )}

        <PaymentInfo>
          {subscription.expirationDate && (
            <Subtitle style={{ textAlign: "end" }}>
              {willRenew
                ? localizationStrings.subscribed_plan_card.next_payment
                : localizationStrings.subscribed_plan_card.active_util}
            </Subtitle>
          )}
          {subscription.expirationDate && (
            <Subtitle style={{ textAlign: "end" }}>{nextPayment}</Subtitle>
          )}
        </PaymentInfo>
      </Price>

      <Separator />

      <Bnefits>
        <BenefitsTitle>
          {localizationStrings.subscribed_plan_card.your_benefits}
        </BenefitsTitle>
        <BenefitsList>
          {benefits.map((item, index) => (
            <BenefitElement
              key={index}
              title={item.title}
              subtitle={item.description}
            />
          ))}
        </BenefitsList>
        {referralPrice === "referral" && (
          <Ivitationinfo>
            {localizationStrings.subscribed_plan_card.invitation_info}
          </Ivitationinfo>
        )}
      </Bnefits>
    </Wrapper>
  );
};

export default SubscribedPlanCard;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 12px;
  background: ${colors.modalDarkPurple};
  box-shadow: 0px 0px 11.3px 0px rgba(0, 0, 0, 0.4);
  gap: 20px;
  border: 1px solid ${colors.purple};
`;

const Separator = styled.div`
  width: 100%;
  background: rgba(46, 50, 62, 1);
  min-height: 1px;
  margin: 10px 0;
`;
const Price = styled.div`
  display: flex;
  align-items: center;
`;
const PriceInfo = styled.div`
width:100%;
font-size: 24px;
font-weight: 600;
line-height: 32px;
text-align: left;
color:${colors.modalGreenColor};
span{
font-weight:400;
font-size:16px;
width:100%;
`;
const PaymentInfo = styled.div`
  min-width: 80px;
`;
const Bnefits = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const BenefitsTitle = styled.h3`
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  text-align: left;
  color: ${colors.modalInfoTextColor};
`;
const BenefitsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
const BenefitWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 30px;
  padding: 10px 0;
`;
const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
`;
const CheckWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Title = styled.h3`
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  text-align: left;
  color: ${colors.white};
`;
const Subtitle = styled.p`
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
  color: ${colors.modalInfoTextColor};
`;
const RefferalPriceInfo = styled(PriceInfo)`
  font-size: 16px;
  line-height: 20px;
  span {
    color: ${colors.modalInfoTextColor};
    font-size: 12px;
  }
`;

const Ivitationinfo = styled.p`
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  color: ${colors.modalInfoTextColor};
  text-align: center;
  margin: 10px 0;
`;
